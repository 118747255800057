@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap);
* {
	margin: 0;
	padding: 0;
  box-sizing: border-box;
  background-color: white;
  color: #282828;
}


html {
	font-family: 'Montserrat', sans-serif;
}

button {
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	-webkit-tap-highlight-color: transparent;
}
.Home {
	position: relative;
	min-height: 100vh;
}
.Home .newest {
	font-weight: 600;
	font-size: 15px;
	color: #282828;
	letter-spacing: 2.5px;
	margin-top: 64px;
	margin-left: 152px;
}



@media only screen and (min-width: 2138px) {
	.Home .newest {
		margin-left: calc((100vw - 510px * 3) / 4);
	}
}



/* TALET */
@media only screen and (max-width: 1050px) {
	.Home .newest {
		margin-top: 64px;
		margin-left: 100px;
	}
}

@media only screen and (max-width: 800px) {
	.Home .newest {
		margin-top: 64px;
		margin-left: 75px;
	}
}


/* PHONE */
@media only screen and (max-width: 650px) {
	.Home .newest {
		margin-top: 64px;
		margin-left: 100px;
	}
}

@media only screen and (max-width: 580px) {
	.Home .newest {
		margin-top: 64px;
		margin-left: 70px;
	}
}

@media only screen and (max-width: 450px) {
	.Home .newest {
		margin-top: 64px;
		margin-left: 40px;
		font-size: 14px;
	}
}
.Header {
	z-index: 100;
}

.header-container {
	top: 0;
	position: fixed;
	display: flex;
	height: 100px;
	width: 100vw;
	flex-direction: row; 
	justify-content: space-between;
	align-items: center;
	padding: 0 152px;
	z-index: 100;
}

.Header .h1 {
	z-index: 10;
	text-decoration: none;
}
.Header h1 {
	font-weight: 700;
	font-size: 20px;
	letter-spacing: 4.5px;
	text-decoration: none;
}

.Header h1 span {
	font-weight: 400;
}


.Header .cat {
	display: flex;
	justify-content: space-between;
	width: 491px;
}

.Header .link {
	text-decoration: none;
}

.Header .cat p {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 11px;
	letter-spacing: 1.5px;
}

.Header .cat:hover p {
	color: white;
}








/* *** PHONE *** */
.phoneNav-container {
	display: none;
	position: fixed;
	top: 0; left: 0; right: 0;
	height: 48px;
}

.phoneNav-container nav {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	position: relative;
}

/* Icon */
.phoneNav-container .menuIcon {
	background-color: white;
	position: absolute;
	width: 57px;
	height: 100%;
	left: 0;
}

.phoneNav-container .menuIcon .icon-container {
	background-color: white;
	width: 21px;
	height: 10px;
	margin-left: 18px;
}

.phoneNav-container .menuIcon .icon-container div {
	display: absolute;
	width: 100%;
	height: 2px;
	background-color: #282828;
	-webkit-transition: .4s;
	transition: .4s;
}

.phoneNav-container .menuIcon .topLayer {
	margin-top: 0px;
}

.phoneNav-container .menuIcon .bottomLayer {
	margin-top: 6px;
}

.phoneNav-container .menuIcon .topLayer-open {
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	margin-top: 5.25px;
}

.phoneNav-container .menuIcon .bottomLayer-open {
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
	margin-top: -2px;
}


/* Brand */
.phoneNav-container .brand {
	font-size: 15px;
	font-weight: 700;
	color: #282828;
	letter-spacing: 5.7px;
}

/* SHARE */
.phoneNav-container .share {
	position: absolute;
	background-color: orange;
	width: 48px;
	height: 100%;
	top: 0px; right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.phoneNav-container .share .button {
	width: 100%;
	height: 100%;
}

.phoneNav-container .share img {
	object-fit: contain;
}




/* Overlay Menu */
.phoneMenuOverlay {
	z-index: 100;
	position: fixed;
	overflow: hidden;
	width: 100%;
	/* height: 122px; */
	top: 48px; 
	-webkit-transition: max-height 0.52s;
	transition: max-height 0.52s;
	-webkit-transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
}

.phoneMenuOverlay .background {
	width: 100%;
	/* height: 122px; */
	height: 220px;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 36px;	
	position: relative;
	z-index: 100;
}


.phoneMenuOverlay .background .text {
	position: relative;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1.7px;
	-webkit-transition: 0.52s;
	transition: 0.52s;
	-webkit-transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	text-decoration: none;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
}

.phoneMenuOverlay .background .text p {
	background-color: rgba(0, 0, 0, 0);
}

.overlayBack {
	position: fixed;
	width: 100%;
	z-index: 99;
	transition: background-color .3s;
	-webkit-transition: background-color .3s;
}

@-webkit-keyframes overlayAlphaChange {
	0%   {background-color: rgba(0, 0, 0, 0)}
	100% {background-color: rgba(0, 0, 0, 0.2)}
}

@keyframes overlayAlphaChange {
	0%   {background-color: rgba(0, 0, 0, 0)}
	100% {background-color: rgba(0, 0, 0, 0.2)}
}

@-webkit-keyframes overlayAlphaChangeBack {
	0%   {background-color: rgba(0, 0, 0, 0.2)}
	100% {background-color: rgba(0, 0, 0, 0)}
}

@keyframes overlayAlphaChangeBack {
	0%   {background-color: rgba(0, 0, 0, 0.2)}
	100% {background-color: rgba(0, 0, 0, 0)}
}





@media only screen and (min-width: 2138px) {
	.header-container {
		padding: 0 calc((100vw - 510px * 3) / 4);
	}
}

/* TALET */
@media only screen and (max-width: 1050px) {
	.header-container { padding: 0 100px; }
}

@media only screen and (max-width: 950px) {
	.header-container { 
		display: none;
		padding: 0 75px; 
	}

	.phoneNav-container {
		z-index: 101;
		display: inline;
	}

	.Header h1 {
		font-weight: 700;
		font-size: 17px;
		letter-spacing: 3px;
	}
}


/* PHONE */
@media only screen and (max-width: 650px) {
	.header-container { padding: 0 100px; }
}

@media only screen and (max-width: 580px) {
	.header-container { padding: 0 70px; }
}

@media only screen and (max-width: 450px) {
	.header-container { padding: 0 40px; }
}


/* Small */

.Footer {
	position: absolute;
	width: 100vw;	
	display: flex;
	justify-content: center;
	margin-bottom: 37px;
	bottom: 0;
	z-index: 10;
	padding: 0 152px;
}

.Footer .footer-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.Footer .links {
	width: 381px;
	display: flex;
	justify-content: space-between;
	word-wrap: break-word;
	align-items: center;
}

.Footer .label {
	font-weight: 500;
	font-size: 12px;
	color: #929292;
	letter-spacing: 1px;
	text-decoration: none;
}

.Footer .appLink {
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.Footer .app-icon {
	width: 46px;
	height: 46px;
}

.appLink .text {
	font-weight: 700;
	font-size: 11px;
	color: #272727;
	letter-spacing: 1px;
	text-align: center;
}



@media only screen and (min-width: 2138px) {
	.Footer { padding: 0 calc((100vw - 510px * 3) / 4) }
}


/* TALET */
@media only screen and (max-width: 1050px) {
	.Footer { padding: 0 100px; }
}

@media only screen and (max-width: 800px) {
	.Footer { padding: 0 75px; }
}



@media only screen and (max-width: 1050px) {
	.Footer { padding: 0; }
	.Footer .footer-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.footer-container .appLink {
		flex-direction: column;
	}

	.appLink .text {
		margin-top: 6px;
	}

	.links {
		margin-top: 49px;

	}
}




/* Small */
@media only screen and (max-width: 500px) {
	.Footer .footer-container {
		padding: 0 30px;
	}

	.Footer .label {
		font-size: 11px;
		letter-spacing: 0px;
	}

	.links {
		max-width: 100%;
	}
}

@media only screen and (max-width: 400px) {
	.Footer .footer-container {
		padding: 0 30px;
	}

	.Footer .label {
		font-weight: 500;
		font-size: 10px;
		color: #929292;
		letter-spacing: 0px;
		text-decoration: none;
	}
}

@media only screen and (max-width: 320px) {
	.Footer .footer-container {
		padding: 0 25px;
	}

	.Footer .label {
		font-size: 9px;
	}
}

@media only screen and (max-width: 260px) {
	.Footer .footer-container {
		padding: 0 20px;
	}

	.Footer .label {
		font-size: 8px;
	}
}
.top-banner {
	width: 100vw;
	height: 586px;
	background-color: white;
	margin-top: 100px;
	box-shadow: 0 40px 40px 0 rgba(0,0,0,0.04);
}

.top-banner p {
	background-color: rgba(0, 0, 0, 0);
	color: #292929;
}

.top-banner .wrapper {
	display: flex;
	flex-direction: row;
	background-color: white;
	justify-content: space-between;
	padding: 0 21vw;
}

.top-banner .infos {
	background-color: rgba(0, 0, 0, 0);
	/* min-width: max-content; */
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.top-banner .header {
	margin-top: 110px;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 6.55px;
}

.top-banner .brand {
	margin-top: 100px;
	font-weight: 700;
	font-size: 24px;
	letter-spacing: 3px;
}


.top-banner .price-container- {
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-top: 20px;
}

.top-banner .salePrice- {
	color: #292929;
	font-weight: 600;
	font-size: 21px;
	letter-spacing: 1px;
}

.top-banner .originalPrice- {
	color: #292929;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 1px;
	margin-left: 20px;
	text-decoration: line-through;
}




.top-banner .image {
	height: 586px;
	object-fit: contain;
	background-color: white;
}








/*  */

.top-banner .content {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	text-decoration: none;
}

.new-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 69px;
	background-color: white;
}

.re {
	font-weight: 700;
	font-size: 24px;
	color: #000000;
	letter-spacing: 2px;
}

.en {
	font-weight: 600;
	font-size: 10px;
	color: #000000;
	letter-spacing: 4.3px;
	margin-left: 35px;
}







@media only screen and (max-width: 1755px) { 
	.top-banner .wrapper {
		padding: 0 17vw;
	}
}


@media only screen and (max-width: 1055px) { 
	.top-banner .wrapper {
		padding: 0 10vw;
	}
}

@media only screen and (max-width: 950px) {
	.top-banner {
		height: 291px;
		position: relative;
	}
	.top-banner {
		margin-top: 48px;
	}

	.top-banner .image {
		position: absolute;
		top: 0;
		right: 70px;
		height: 291px;
		z-index: 9;
	}

	.top-banner .infos {
		position: absolute;
		left: 70px;
		z-index: 10;
	}

	.top-banner .header {
		font-weight: 800;
		margin-top: 0;
		font-size: 12px;
		letter-spacing: 1px;
		margin-top: 40px;
	}
	
	.top-banner .brand {
		font-weight: 700;
		margin-top: 43px;
		font-size: 20px;
		letter-spacing: 1px;
	}

	.top-banner .price-container- {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 24px;
	}

	.top-banner .salePrice- {
		font-weight: 600;
		font-size: 16px;
		letter-spacing: 1px;
	}
	
	.top-banner .originalPrice- {
		font-weight: 500;
		font-size: 13px;
		margin-left: 0;
	}





	/* --------- */
	.new-wrapper {
		flex-direction: column;
		height: 50px;
	}

	.en {
		margin-left: 0;
	}
	
	.re {
		letter-spacing: 0.7px;
	}
}

@media only screen and (max-width: 540px) {
	.top-banner .image {
		right: 30px;
	}

	.top-banner .infos {
		left: 30px;
	}

	.top-banner .header {
		margin-top: 30px;
	}
}

@media only screen and (max-width: 450px) {
	.top-banner .image {
		right: 20px;
	}

	.top-banner .infos {
		left: 20px;
	}

	.top-banner .header {
		font-size: 10px;
		letter-spacing: 1px;
		margin-top: 20px;
	}
	
	.top-banner .brand {
		margin-top: 43px;
		font-size: 17px;
		letter-spacing: 1px;
	}

	.top-banner .price-container- {
		margin-top: 24px;
	}

	.top-banner .salePrice- {
		font-weight: 600;
		font-size: 14px;
		letter-spacing: 1px;
	}
	
	.top-banner .originalPrice- {
		font-weight: 500;
		font-size: 12px;
	}
}
.Collection {
	margin-top: 137px;
}

.Collection .loading {
	margin-top: 40px;
}

.Collection div {
	background-color: rgba(0, 0, 0, 0);
}

.collection-wrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: -webkit-max-content;
	grid-template-rows: max-content;
	grid-column-gap: 152px;
	grid-row-gap: 137px;
	margin: 0 152px;
}

.Collection .img-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.Collection .image {
	width: calc((100vw - 152px * 4) / 3);
	height: calc((100vw - 152px * 4) / 3);
	max-width: 510px;
	max-height: 510px;
	border-radius: 6px;
}

.Collection .info-wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.imgContainer .brand {
	width: 100%;
	max-width: 510px;
	font-weight: 500;
	font-size: 15px;
	color: #282828;
	letter-spacing: 4px;
	text-transform: uppercase;
	margin-top: 19px;
}

.imgContainer .price {
	width: 100%;
	max-width: 510px;
	display: flex;
	flex-direction: row;
	margin-top: 5px;
}

.imgContainer .salePrice {
	font-weight: 700;
	font-size: 14px;
	/* color: #BE1919; */
	color: #282828;
	letter-spacing: 0.5px;
}

.imgContainer .originalPrice {
	font-weight: 600;
	font-size: 14px;
	color: #BABABA;
	letter-spacing: 0px;
	margin-left: 16px;
	text-decoration: line-through;
}

.Collection .loading-wrapper {
	margin-top: 82px;
	display: flex;
	justify-content: center;
	padding-bottom: 170px;
}
.Collection .loading {
	font-weight: 500;
	font-size: 11px;
	color: #282828;
	letter-spacing: 1.69px;
	text-align: center;
}

.Collection .error-label {
	font-weight: 500;
	font-size: 11px;
	color: #282828;
	letter-spacing: 1.69px;
	text-align: center;
}



@media only screen and (min-width: 2138px) {
	.collection-wrapper {
		grid-column-gap: calc((100vw - 510px * 3) / 4);
		margin: 0 calc((100vw - 510px * 3) / 4);
	}
}




/* TALET */
@media only screen and (max-width: 1480px) {
	.collection-wrapper {
		grid-template-columns: repeat(2, 1fr);
	}

	.Collection .image {
		width: calc((100vw - 152px * 3) / 2);
		height: calc((100vw - 152px * 3) / 2);
	}
}

@media only screen and (max-width: 1050px) {
	.Collection .image {
		width: calc((100vw - 100px * 3) / 2);
		height: calc((100vw - 100px * 3) / 2);
	}

	.collection-wrapper {
		grid-column-gap: 100px;
		grid-row-gap: 80px;
		margin: 0 100px;
	}

	.Collection .loading-wrapper {
		padding-bottom: 210px;
	}
}

@media only screen and (max-width: 950px) {
	.Collection {
		margin-top: 89px;
	}

	.imgContainer .brand {
		margin-top: 12px;
		font-size: 11px;
		letter-spacing: 2px;
		font-weight: 700;
	}

	.imgContainer .price {
		margin-top: 1px;
	}

	.imgContainer .salePrice {
		font-size: 13px;
	}
	
	.imgContainer .originalPrice {
		font-size: 12px;;
	}
}

@media only screen and (max-width: 800px) {
	.Collection .image {
		width: calc((100vw - 75px * 3) / 2);
		height: calc((100vw - 75px * 3) / 2);
	}

	.collection-wrapper {
		grid-column-gap: 75px;
		grid-row-gap: 60px;
		margin: 0 75px;
	}
}


/* PHONE */
@media only screen and (max-width: 650px) {
	.Collection .image {
		width: calc((100vw - 100px * 2) / 1);
		height: calc((100vw - 100px * 2) / 1);
	}

	.collection-wrapper {
		grid-template-columns: repeat(1, 1fr);
		grid-column-gap: 100px;
		grid-row-gap: 80px;
		margin: 0 100px;
	}

	.Collection .error-label {
		font-weight: 600;
		padding: 0 30px;
	}
}

@media only screen and (max-width: 580px) {
	.Collection .image {
		width: calc((100vw - 70px * 2) / 1);
		height: calc((100vw - 70px * 2) / 1);
	}

	.collection-wrapper {
		grid-column-gap: 70px;
		grid-row-gap: 60px;
		margin: 0 70px;
	}
}

@media only screen and (max-width: 450px) {
	.Collection .image {
		width: calc((100vw - 40px * 2) / 1);
		height: calc((100vw - 40px * 2) / 1);
	}

	.collection-wrapper {
		grid-column-gap: 40px;
		grid-row-gap: 40px;
		margin: 0 40px;
	}

	.Collection {
		margin-top: 70px;
	}

	/* .Collection .loading-wrapper {
		padding-bottom: 110px;
	} */
}
.Impressum {
	position: relative;
}

.Impressum-wrapper {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Impressum-wrapper .wrapper {
	width: 60vw;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	margin-top: 190px;
	padding-bottom: 200px;
}

.Impressum-wrapper .header {
	font-size: 20px;
	font-weight: 800;
	letter-spacing: 2.2px;
	margin-bottom: 61px;
}

.Impressum-wrapper .infos-wrapper {
	display: flex;
}

.Impressum-wrapper .infos-wrapper .seconde_container {
	margin-left: 292px;
}

.Impressum-wrapper .title {
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 1.27px;
	text-align: left;
	margin-bottom: 11px;
	margin-top: 23px;
}	

.Impressum-wrapper .content {
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 3px;
}	


.Impressum-wrapper .infos-wrapper .content-m {
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 24px;
}	

.Impressum-wrapper .content-recht {
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 24px;
}	

.Impressum-wrapper .block-container {
	margin-top: 49px;
}



/* ___________ TABLET ___________ */
@media only screen and (max-width: 1100px) {
	.Impressum-wrapper {
		min-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.Impressum-wrapper .wrapper {
		margin-top: 0px;
		min-height: 100vh;
		width: 100vw;
		padding: 120px 40px 180px 40px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
	}

	.Impressum-wrapper .infos-wrapper .seconde_container {
		margin-left: 30px;
	}

	.Impressum-wrapper .content {
		letter-spacing: 0px;
	}	
}


@media only screen and (max-width: 750px) {
	.Impressum-wrapper .wrapper {
		padding: 80px 20px 150px 20px;
	}


	.Impressum-wrapper .infos-wrapper {
		flex-direction: column;
	}

	.Impressum-wrapper .infos-wrapper .seconde_container {
		margin-left: 0px;
	}

	.Impressum-wrapper .header {
		margin-bottom: 31px;
	}

	.Impressum-wrapper a {
		color: #3FA1EC;
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;
	
		-ms-word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;
	
		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

	.Impressum-wrapper .content {
		line-height: 17px;
		font-size: 13px;
	}	
}
.Datenschutz {
	position: relative;
}

.Datenschutz .container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Datenschutz .container .wrapper {
	padding: 136px 0 190px 0;
	min-height: 100vh;
	width: 60vw;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}

.Datenschutz .container h1 {
	font-weight: 800;
	font-size: 30px;
	letter-spacing: 1px;
	text-align: left;
}

.Datenschutz .container h2 {
	font-weight: 700;
	font-size: 25px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 50px;
}

.Datenschutz .container h3 {
	font-weight: 700;
	font-size: 20px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 15px;
	margin-bottom: 5px;
}

.Datenschutz .container p {
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 1px;
	text-align: left;
	text-transform: none;
}

.Datenschutz ul {
	list-style-position: inside;
}

@media only screen and (max-width: 1600px) {
	.Datenschutz .container .wrapper  {
		width: 75vw;
	}
}

@media only screen and (max-width: 1450px) {
	.Datenschutz .container .wrapper  {
		width: 84vw;
	}
}


/* ___________ TABLET ___________ */
@media only screen and (max-width: 1100px) {
	.Datenschutz .container .wrapper  {
		min-height: 100vh;
		width: 100vw;
		padding: 140px 50px 150px 50px;
	}

	.Datenschutz .container h1 {
		font-size: 25px;
	}

	.Datenschutz .container h2 {
		font-size: 22px;
	}

	.Datenschutz .container h3 {
		font-size: 18px;
	}
}

@media only screen and (max-width: 750px) {
	.Datenschutz .container .wrapper  {
		padding: 100px 30px 150px 30px;
	}


	.Datenschutz .container h1 {
		font-size: 23px;
	}

	.Datenschutz .container h2 {
		font-size: 20px;
	}

	.Datenschutz .container h3 {
		font-size: 16px;
		margin-top: 50px;
		margin-bottom: 5px;
	}

	.Datenschutz .container p {
		font-size: 13px;
		letter-spacing: 1px;
		width: 100%;
		line-height: 20px;
	}


	.Datenschutz .container a {
		color: #3FA1EC;
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;
	
		-ms-word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;
	
		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

}


.AppDatenschutz .h1 {
	z-index: 10;
	text-decoration: none;
}
.AppDatenschutz .h1 {
	font-weight: 700;
	font-size: 20px;
	letter-spacing: 4.5px;
	text-decoration: none;
}

.AppDatenschutz .h1 span {
	font-weight: 400;
}

.AppDatenschutz .brand {
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 100px 0;
}

.AppDatenschutz .wrapper {
	display: flex;
	align-items: flex-end;
}

.AppDatenschutz .h2 {
	margin-left: 12px;
	font-size: 13px;
	font-weight: 600;
}


.AppDatenschutz .extern-link {
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.AppDatenschutz #link {
	font-size: 12px;
}



/* PHONE */
@media only screen and (max-width: 850px) {
	
	.AppDatenschutz .brand {
		margin: 30px 0;
	}

	.AppDatenschutz .h1 {
		font-size: 19px;
		letter-spacing: 1px;
	}
}
.Contact {
	z-index: 1;
}

.contact-container {
	top: 0; left: 0; bottom: 0;right: 0;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0);
	z-index: 1;
}

.contact-container a {
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: center;
	text-decoration: none;
}
.Page404 {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Page404 .wrapper {
	display: inline;
}

.Page404 h1 {
	font-size: 28px;
	letter-spacing: 5px;
	font-weight: 700;
}

.Page404 h2 {
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 2px;
	margin: 8px 0 0 0;
}

.Page404 .link p {
	padding: 10px 0 0 29px;
	background-color: #303030;
	color: white;
	width: 164px;
	height: 36px;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 6px;
	text-align: left;
	margin-top: 54px;
}


@media only screen and (max-width: 1100px) {
	.Page404 .wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.Page404 .link p {
		padding-left: 0;
		text-align: center;
		margin-top: 0;
	}

	.Page404 .link {
		margin-top: 54px;
	}

	.Page404 h2 {
		font-size: 10px;
		font-weight: 600;
		letter-spacing: 1.5px;
		text-align: center;
		margin: 8px 15px 0 15px;
	}

	.WholePage404 {
		position: relative;
		padding-bottom: 170px;
	}
}

.CollectionPage {
	position: relative;
	min-height: 100vh;
	z-index: 10;
}
.ItemView {
	position: relative;
	min-height: 100vh;
}


/* IMAGES */
.ItemView .image-container {
	margin-top: 100px;
	height: 473px;
	width: 100vw;
	display: flex;
	justify-content: center;
}

.image-container .wrapper {
	display: flex;
	overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE and Edge */
.wrapper {
	-ms-overflow-style: none;
}

.image-container .image {
	min-width: 473px;
	width: 473px;
	height: 473px;
	/* border-radius: 7px; */
}



/* CLOTHING INFO */
.ItemView .clothing-info-container {
	width: 100vw;
	/* margin-top: 4.375rem; */
	margin-top: 70px;
	display: flex;
	justify-content: center;
}

.clothing-info-container .infos {
	display: flex;
	align-items: center;
	flex-direction: column;
}


.clothing-info-container h2 {
	font-weight: 700;
	font-size: 27px;
	color: #232323;
	letter-spacing: 4px;
	text-align: center;
	text-transform: uppercase;
}

.clothing-info-container h3 {
	font-weight: 600;
	font-size: 0.875rem;
	color: #232323;
	letter-spacing: 3.8px;
	text-transform: uppercase;
	margin-top: 8px;
	text-align: center;
}

.clothing-info-container .price-container {
	display: flex;
	flex-direction: row;
	margin-top: 27px;
	align-items: flex-end;
}

.price-container .salePrice {
	font-weight: 700;
	font-size: 16px;
	color: #232323;
	letter-spacing: 1px;
}

.price-container .originalPrice {
	font-weight: 500;
	font-size: 12px;
	color: #232323;
	letter-spacing: 1.6px;
	margin-left: 16px;
	text-decoration: line-through;
}

.ItemView .buy-button {
	width: 212px;
	height: 45px;
	border-radius: 22.5px;
	background-color: #232323;
	font-weight: 700;
	font-size: 16px;
	color: white;
	letter-spacing: 0.62px;
	text-align: center;
	margin-top: 64px;
	-webkit-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
}




/* MORE SHOP INFO CONTAINER */
.ItemView .shop-info {
	margin-top: 70px;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.ItemView .line {
	width: calc(100% - 92px);
	max-width: 790px;
	border: 0.6px solid #E3E3E3;
}

.ItemView .more-info-container {
	display: flex;
	flex-direction: row;
	padding: 63px 0;
	width: calc(100vw - 92px);
	max-width: 790px;
}


.ItemView .simplebar :before {
	background-color: #232323;
	border-radius: 0;
}

.ItemView .simplebar {
	width: calc(100vw - 92px);
	max-width: 790px;
	padding: 0;
	margin: 0;
}

.more-info-container .wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 158px;
	min-width: 158px;
	text-decoration: none;
	text-transform: uppercase;
}

.more-info-container .header {
	font-weight: 500;
	font-size: 9px;
	color: #232323;
	letter-spacing: 2.1px;
}

.more-info-container .info {
	margin-top: 4px;
	font-weight: 600;
	font-size: 12px;
	color: #232323;
	letter-spacing: 1.1px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
}

.more-info-container .info img {
	margin-left: 4px;
}



/* BRAND TEXT */
.ItemView .brand-text {
	width: 100vw;
	margin-top: 70px;
	padding-bottom: 300px;
	display: flex;
	justify-content: center;
}

.ItemView .brand-text p {
	line-height: 23px;
	max-width: 790px;
	width: calc(100vw - 92px);
	text-align: center;
	font-size: 16px;
}



/* PHONE */
@media only screen and (max-width: 950px), only screen and (min-height: 1000px) and (max-width: 1200px) {
	
	/* INFOS */
	.clothing-info-container .infos {
		max-width: calc(100vw - 60px);
		max-height: -webkit-max-content;
		max-height: -moz-max-content;
		max-height: max-content;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.clothing-info-container h3 {
		letter-spacing: 2px;
		margin-top: 8px;
		text-align: center;
	}
	
	.clothing-info-container .price-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 39px;
	}
	
	.price-container .salePrice {
		font-weight: 700;
		font-size: 20px;
		letter-spacing: 1.6px;
	}
	
	.price-container .originalPrice {
		font-size: 15px;
		letter-spacing: 1.7px;
		margin-left: 0;
	}
	
	
}

@media only screen and (max-width: 1050px) {
	.ItemView .brand-text {
		padding-bottom: 250px;
	}
}

@media only screen and (max-width: 950px) {
	.ItemView .image-container {
		margin-top: 48px;
	}
}


@media only screen and (max-width: 881px) {
	.ItemView .line-950 {
		display: none;
	}
}

@media only screen and (max-width: 600px) {
	/* IMAGES */
	.ItemView .image-container {
		height: 300px;
	}

	.image-container .image {
		min-width: 300px;
		width: 300px;
		height: 300px;
	}

	.clothing-info-container h2 {
		font-size: 21px;
		letter-spacing: 2.5px;
	}

	.clothing-info-container h3 {
		font-size: 0.76rem;
		letter-spacing: 3px;
	}

	.ItemView .brand-text p {
		font-size: 14px;
	}


	.ItemView .more-info-container {
		padding: 50px 0;
	}
}

@media only screen and (max-width: 500px) {
	.clothing-info-container h2 {
		font-size: 20px;
		letter-spacing: 1.2px;
	}

	.clothing-info-container h3 {
		font-size: 0.7rem;
		letter-spacing: 2px;
	}

	.price-container .salePrice {
		font-size: 16px;
	}
	
	.price-container .originalPrice {
		font-size: 13px;
	}
}
[data-simplebar] {
	position: relative;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-start;
  }
  
  .simplebar-wrapper {
	overflow: hidden;
	width: inherit;
	height: inherit;
	max-width: inherit;
	max-height: inherit;
  }
  
  .simplebar-mask {
	direction: inherit;
	position: absolute;
	overflow: hidden;
	padding: 0;
	margin: 0;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: auto !important;
	height: auto !important;
	z-index: 0;
  }
  
  .simplebar-offset {
	direction: inherit !important;
	box-sizing: inherit !important;
	resize: none !important;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 0;
	margin: 0;
	-webkit-overflow-scrolling: touch;
  }
  
  .simplebar-content-wrapper {
	direction: inherit;
	box-sizing: border-box !important;
	position: relative;
	display: block;
	height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
	width: auto;
	visibility: visible;
	max-width: 100%; /* Not required for horizontal scroll to trigger */
	max-height: 100%; /* Needed for vertical scroll to trigger */
	scrollbar-width: none;
	-ms-overflow-style: none;
  }
  
  .simplebar-content-wrapper::-webkit-scrollbar,
  .simplebar-hide-scrollbar::-webkit-scrollbar {
	width: 0;
	height: 0;
	display: none;
  }
  
  .simplebar-content:before,
  .simplebar-content:after {
	content: ' ';
	display: table;
  }
  
  .simplebar-placeholder {
	max-height: 100%;
	max-width: 100%;
	width: 100%;
	pointer-events: none;
  }
  
  .simplebar-height-auto-observer-wrapper {
	box-sizing: inherit !important;
	height: 100%;
	width: 100%;
	max-width: 1px;
	position: relative;
	float: left;
	max-height: 1px;
	overflow: hidden;
	z-index: -1;
	padding: 0;
	margin: 0;
	pointer-events: none;
	flex-grow: inherit;
	flex-shrink: 0;
	flex-basis: 0;
  }
  
  .simplebar-height-auto-observer {
	box-sizing: inherit;
	display: block;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	height: 1000%;
	width: 1000%;
	min-height: 1px;
	min-width: 1px;
	overflow: hidden;
	pointer-events: none;
	z-index: -1;
  }
  
  .simplebar-track {
	z-index: 1;
	position: absolute;
	right: 0;
	bottom: 0;
	pointer-events: none;
	overflow: hidden;
  }
  
  [data-simplebar].simplebar-dragging .simplebar-content {
	pointer-events: none;
	-moz-user-select: none;
	 -ms-user-select: none;
	     user-select: none;
	-webkit-user-select: none;
  }
  
  [data-simplebar].simplebar-dragging .simplebar-track {
	pointer-events: all;
  }
  
  .simplebar-scrollbar {
	position: absolute;
	left: 0;
	right: 0;
	min-height: 10px;
  }
  
  .simplebar-scrollbar:before {
	position: absolute;
	content: '';
	background: black;
	border-radius: 7px;
	left: 2px;
	right: 2px;
	opacity: 0;
	-webkit-transition: opacity 0.2s linear;
	transition: opacity 0.2s linear;
  }
  
  .simplebar-scrollbar.simplebar-visible:before {
	/* When hovered, remove all transitions from drag handle */
	opacity: 0.5;
	-webkit-transition: opacity 0s linear;
	transition: opacity 0s linear;
  }
  
  .simplebar-track.simplebar-vertical {
	top: 0;
	width: 11px;
  }
  
  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
	top: 2px;
	bottom: 2px;
  }
  
  .simplebar-track.simplebar-horizontal {
	left: 0;
	height: 1px;
	background-color: #E3E3E3;
  }
  
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
	height: 100%;
	left: 0px;
	right: 0px;
  }
  
  /* CUSTOME */
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
	right: auto;
	left: 0;
	top: 0px;
	height: 1px;
	min-height: 0;
	min-width: 10px;
	width: auto;
  }
  
  /* Rtl support */
  [data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
	right: auto;
	left: 0;
  }
  
  .hs-dummy-scrollbar-size {
	direction: rtl;
	position: fixed;
	opacity: 0;
	visibility: hidden;
	height: 500px;
	width: 500px;
	overflow-y: hidden;
	overflow-x: scroll;
  }
  
  .simplebar-hide-scrollbar {
	position: fixed;
	left: 0;
	visibility: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
  }

