.ItemView {
	position: relative;
	min-height: 100vh;
}


/* IMAGES */
.ItemView .image-container {
	margin-top: 100px;
	height: 473px;
	width: 100vw;
	display: flex;
	justify-content: center;
}

.image-container .wrapper {
	display: flex;
	overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE and Edge */
.wrapper {
	-ms-overflow-style: none;
}

.image-container .image {
	min-width: 473px;
	width: 473px;
	height: 473px;
	/* border-radius: 7px; */
}



/* CLOTHING INFO */
.ItemView .clothing-info-container {
	width: 100vw;
	/* margin-top: 4.375rem; */
	margin-top: 70px;
	display: flex;
	justify-content: center;
}

.clothing-info-container .infos {
	display: flex;
	align-items: center;
	flex-direction: column;
}


.clothing-info-container h2 {
	font-weight: 700;
	font-size: 27px;
	color: #232323;
	letter-spacing: 4px;
	text-align: center;
	text-transform: uppercase;
}

.clothing-info-container h3 {
	font-weight: 600;
	font-size: 0.875rem;
	color: #232323;
	letter-spacing: 3.8px;
	text-transform: uppercase;
	margin-top: 8px;
	text-align: center;
}

.clothing-info-container .price-container {
	display: flex;
	flex-direction: row;
	margin-top: 27px;
	align-items: flex-end;
}

.price-container .salePrice {
	font-weight: 700;
	font-size: 16px;
	color: #232323;
	letter-spacing: 1px;
}

.price-container .originalPrice {
	font-weight: 500;
	font-size: 12px;
	color: #232323;
	letter-spacing: 1.6px;
	margin-left: 16px;
	text-decoration: line-through;
}

.ItemView .buy-button {
	width: 212px;
	height: 45px;
	border-radius: 22.5px;
	background-color: #232323;
	font-weight: 700;
	font-size: 16px;
	color: white;
	letter-spacing: 0.62px;
	text-align: center;
	margin-top: 64px;
	transition: 0.2s ease-in;
}




/* MORE SHOP INFO CONTAINER */
.ItemView .shop-info {
	margin-top: 70px;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.ItemView .line {
	width: calc(100% - 92px);
	max-width: 790px;
	border: 0.6px solid #E3E3E3;
}

.ItemView .more-info-container {
	display: flex;
	flex-direction: row;
	padding: 63px 0;
	width: calc(100vw - 92px);
	max-width: 790px;
}


.ItemView .simplebar :before {
	background-color: #232323;
	border-radius: 0;
}

.ItemView .simplebar {
	width: calc(100vw - 92px);
	max-width: 790px;
	padding: 0;
	margin: 0;
}

.more-info-container .wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 158px;
	min-width: 158px;
	text-decoration: none;
	text-transform: uppercase;
}

.more-info-container .header {
	font-weight: 500;
	font-size: 9px;
	color: #232323;
	letter-spacing: 2.1px;
}

.more-info-container .info {
	margin-top: 4px;
	font-weight: 600;
	font-size: 12px;
	color: #232323;
	letter-spacing: 1.1px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
}

.more-info-container .info img {
	margin-left: 4px;
}



/* BRAND TEXT */
.ItemView .brand-text {
	width: 100vw;
	margin-top: 70px;
	padding-bottom: 300px;
	display: flex;
	justify-content: center;
}

.ItemView .brand-text p {
	line-height: 23px;
	max-width: 790px;
	width: calc(100vw - 92px);
	text-align: center;
	font-size: 16px;
}



/* PHONE */
@media only screen and (max-width: 950px), only screen and (min-height: 1000px) and (max-width: 1200px) {
	
	/* INFOS */
	.clothing-info-container .infos {
		max-width: calc(100vw - 60px);
		max-height: max-content;
		width: max-content;
		height: max-content;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.clothing-info-container h3 {
		letter-spacing: 2px;
		margin-top: 8px;
		text-align: center;
	}
	
	.clothing-info-container .price-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 39px;
	}
	
	.price-container .salePrice {
		font-weight: 700;
		font-size: 20px;
		letter-spacing: 1.6px;
	}
	
	.price-container .originalPrice {
		font-size: 15px;
		letter-spacing: 1.7px;
		margin-left: 0;
	}
	
	
}

@media only screen and (max-width: 1050px) {
	.ItemView .brand-text {
		padding-bottom: 250px;
	}
}

@media only screen and (max-width: 950px) {
	.ItemView .image-container {
		margin-top: 48px;
	}
}


@media only screen and (max-width: 881px) {
	.ItemView .line-950 {
		display: none;
	}
}

@media only screen and (max-width: 600px) {
	/* IMAGES */
	.ItemView .image-container {
		height: 300px;
	}

	.image-container .image {
		min-width: 300px;
		width: 300px;
		height: 300px;
	}

	.clothing-info-container h2 {
		font-size: 21px;
		letter-spacing: 2.5px;
	}

	.clothing-info-container h3 {
		font-size: 0.76rem;
		letter-spacing: 3px;
	}

	.ItemView .brand-text p {
		font-size: 14px;
	}


	.ItemView .more-info-container {
		padding: 50px 0;
	}
}

@media only screen and (max-width: 500px) {
	.clothing-info-container h2 {
		font-size: 20px;
		letter-spacing: 1.2px;
	}

	.clothing-info-container h3 {
		font-size: 0.7rem;
		letter-spacing: 2px;
	}

	.price-container .salePrice {
		font-size: 16px;
	}
	
	.price-container .originalPrice {
		font-size: 13px;
	}
}