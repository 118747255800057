.Home {
	position: relative;
	min-height: 100vh;
}
.Home .newest {
	font-weight: 600;
	font-size: 15px;
	color: #282828;
	letter-spacing: 2.5px;
	margin-top: 64px;
	margin-left: 152px;
}



@media only screen and (min-width: 2138px) {
	.Home .newest {
		margin-left: calc((100vw - 510px * 3) / 4);
	}
}



/* TALET */
@media only screen and (max-width: 1050px) {
	.Home .newest {
		margin-top: 64px;
		margin-left: 100px;
	}
}

@media only screen and (max-width: 800px) {
	.Home .newest {
		margin-top: 64px;
		margin-left: 75px;
	}
}


/* PHONE */
@media only screen and (max-width: 650px) {
	.Home .newest {
		margin-top: 64px;
		margin-left: 100px;
	}
}

@media only screen and (max-width: 580px) {
	.Home .newest {
		margin-top: 64px;
		margin-left: 70px;
	}
}

@media only screen and (max-width: 450px) {
	.Home .newest {
		margin-top: 64px;
		margin-left: 40px;
		font-size: 14px;
	}
}