* {
	margin: 0;
	padding: 0;
  box-sizing: border-box;
  background-color: white;
  color: #282828;
}


html {
	font-family: 'Montserrat', sans-serif;
}

button {
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	-webkit-tap-highlight-color: transparent;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap');