.Header {
	z-index: 100;
}

.header-container {
	top: 0;
	position: fixed;
	display: flex;
	height: 100px;
	width: 100vw;
	flex-direction: row; 
	justify-content: space-between;
	align-items: center;
	padding: 0 152px;
	z-index: 100;
}

.Header .h1 {
	z-index: 10;
	text-decoration: none;
}
.Header h1 {
	font-weight: 700;
	font-size: 20px;
	letter-spacing: 4.5px;
	text-decoration: none;
}

.Header h1 span {
	font-weight: 400;
}


.Header .cat {
	display: flex;
	justify-content: space-between;
	width: 491px;
}

.Header .link {
	text-decoration: none;
}

.Header .cat p {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 11px;
	letter-spacing: 1.5px;
}

.Header .cat:hover p {
	color: white;
}








/* *** PHONE *** */
.phoneNav-container {
	display: none;
	position: fixed;
	top: 0; left: 0; right: 0;
	height: 48px;
}

.phoneNav-container nav {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	position: relative;
}

/* Icon */
.phoneNav-container .menuIcon {
	background-color: white;
	position: absolute;
	width: 57px;
	height: 100%;
	left: 0;
}

.phoneNav-container .menuIcon .icon-container {
	background-color: white;
	width: 21px;
	height: 10px;
	margin-left: 18px;
}

.phoneNav-container .menuIcon .icon-container div {
	display: absolute;
	width: 100%;
	height: 2px;
	background-color: #282828;
	-webkit-transition: .4s;
	-moz-transition: .4s;
	-o-transition: .4s;
	transition: .4s;
}

.phoneNav-container .menuIcon .topLayer {
	margin-top: 0px;
}

.phoneNav-container .menuIcon .bottomLayer {
	margin-top: 6px;
}

.phoneNav-container .menuIcon .topLayer-open {
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
	margin-top: 5.25px;
}

.phoneNav-container .menuIcon .bottomLayer-open {
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);
	margin-top: -2px;
}


/* Brand */
.phoneNav-container .brand {
	font-size: 15px;
	font-weight: 700;
	color: #282828;
	letter-spacing: 5.7px;
}

/* SHARE */
.phoneNav-container .share {
	position: absolute;
	background-color: orange;
	width: 48px;
	height: 100%;
	top: 0px; right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.phoneNav-container .share .button {
	width: 100%;
	height: 100%;
}

.phoneNav-container .share img {
	object-fit: contain;
}




/* Overlay Menu */
.phoneMenuOverlay {
	z-index: 100;
	position: fixed;
	overflow: hidden;
	width: 100%;
	/* height: 122px; */
	top: 48px; 
	-webkit-transition: max-height 0.52s;
	transition: max-height 0.52s;
	-webkit-transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
}

.phoneMenuOverlay .background {
	width: 100%;
	/* height: 122px; */
	height: 220px;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 36px;	
	position: relative;
	z-index: 100;
}


.phoneMenuOverlay .background .text {
	position: relative;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1.7px;
	-webkit-transition: 0.52s;
	transition: 0.52s;
	-webkit-transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	width: max-content;
	text-decoration: none;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
}

.phoneMenuOverlay .background .text p {
	background-color: rgba(0, 0, 0, 0);
}

.overlayBack {
	position: fixed;
	width: 100%;
	z-index: 99;
	transition: background-color .3s;
	-webkit-transition: background-color .3s;
}

@keyframes overlayAlphaChange {
	0%   {background-color: rgba(0, 0, 0, 0)}
	100% {background-color: rgba(0, 0, 0, 0.2)}
}

@keyframes overlayAlphaChangeBack {
	0%   {background-color: rgba(0, 0, 0, 0.2)}
	100% {background-color: rgba(0, 0, 0, 0)}
}





@media only screen and (min-width: 2138px) {
	.header-container {
		padding: 0 calc((100vw - 510px * 3) / 4);
	}
}

/* TALET */
@media only screen and (max-width: 1050px) {
	.header-container { padding: 0 100px; }
}

@media only screen and (max-width: 950px) {
	.header-container { 
		display: none;
		padding: 0 75px; 
	}

	.phoneNav-container {
		z-index: 101;
		display: inline;
	}

	.Header h1 {
		font-weight: 700;
		font-size: 17px;
		letter-spacing: 3px;
	}
}


/* PHONE */
@media only screen and (max-width: 650px) {
	.header-container { padding: 0 100px; }
}

@media only screen and (max-width: 580px) {
	.header-container { padding: 0 70px; }
}

@media only screen and (max-width: 450px) {
	.header-container { padding: 0 40px; }
}


/* Small */
