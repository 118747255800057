.Contact {
	z-index: 1;
}

.contact-container {
	top: 0; left: 0; bottom: 0;right: 0;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0);
	z-index: 1;
}

.contact-container a {
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: center;
	text-decoration: none;
}