.Collection {
	margin-top: 137px;
}

.Collection .loading {
	margin-top: 40px;
}

.Collection div {
	background-color: rgba(0, 0, 0, 0);
}

.collection-wrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: max-content;
	grid-column-gap: 152px;
	grid-row-gap: 137px;
	margin: 0 152px;
}

.Collection .img-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.Collection .image {
	width: calc((100vw - 152px * 4) / 3);
	height: calc((100vw - 152px * 4) / 3);
	max-width: 510px;
	max-height: 510px;
	border-radius: 6px;
}

.Collection .info-wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.imgContainer .brand {
	width: 100%;
	max-width: 510px;
	font-weight: 500;
	font-size: 15px;
	color: #282828;
	letter-spacing: 4px;
	text-transform: uppercase;
	margin-top: 19px;
}

.imgContainer .price {
	width: 100%;
	max-width: 510px;
	display: flex;
	flex-direction: row;
	margin-top: 5px;
}

.imgContainer .salePrice {
	font-weight: 700;
	font-size: 14px;
	/* color: #BE1919; */
	color: #282828;
	letter-spacing: 0.5px;
}

.imgContainer .originalPrice {
	font-weight: 600;
	font-size: 14px;
	color: #BABABA;
	letter-spacing: 0px;
	margin-left: 16px;
	text-decoration: line-through;
}

.Collection .loading-wrapper {
	margin-top: 82px;
	display: flex;
	justify-content: center;
	padding-bottom: 170px;
}
.Collection .loading {
	font-weight: 500;
	font-size: 11px;
	color: #282828;
	letter-spacing: 1.69px;
	text-align: center;
}

.Collection .error-label {
	font-weight: 500;
	font-size: 11px;
	color: #282828;
	letter-spacing: 1.69px;
	text-align: center;
}



@media only screen and (min-width: 2138px) {
	.collection-wrapper {
		grid-column-gap: calc((100vw - 510px * 3) / 4);
		margin: 0 calc((100vw - 510px * 3) / 4);
	}
}




/* TALET */
@media only screen and (max-width: 1480px) {
	.collection-wrapper {
		grid-template-columns: repeat(2, 1fr);
	}

	.Collection .image {
		width: calc((100vw - 152px * 3) / 2);
		height: calc((100vw - 152px * 3) / 2);
	}
}

@media only screen and (max-width: 1050px) {
	.Collection .image {
		width: calc((100vw - 100px * 3) / 2);
		height: calc((100vw - 100px * 3) / 2);
	}

	.collection-wrapper {
		grid-column-gap: 100px;
		grid-row-gap: 80px;
		margin: 0 100px;
	}

	.Collection .loading-wrapper {
		padding-bottom: 210px;
	}
}

@media only screen and (max-width: 950px) {
	.Collection {
		margin-top: 89px;
	}

	.imgContainer .brand {
		margin-top: 12px;
		font-size: 11px;
		letter-spacing: 2px;
		font-weight: 700;
	}

	.imgContainer .price {
		margin-top: 1px;
	}

	.imgContainer .salePrice {
		font-size: 13px;
	}
	
	.imgContainer .originalPrice {
		font-size: 12px;;
	}
}

@media only screen and (max-width: 800px) {
	.Collection .image {
		width: calc((100vw - 75px * 3) / 2);
		height: calc((100vw - 75px * 3) / 2);
	}

	.collection-wrapper {
		grid-column-gap: 75px;
		grid-row-gap: 60px;
		margin: 0 75px;
	}
}


/* PHONE */
@media only screen and (max-width: 650px) {
	.Collection .image {
		width: calc((100vw - 100px * 2) / 1);
		height: calc((100vw - 100px * 2) / 1);
	}

	.collection-wrapper {
		grid-template-columns: repeat(1, 1fr);
		grid-column-gap: 100px;
		grid-row-gap: 80px;
		margin: 0 100px;
	}

	.Collection .error-label {
		font-weight: 600;
		padding: 0 30px;
	}
}

@media only screen and (max-width: 580px) {
	.Collection .image {
		width: calc((100vw - 70px * 2) / 1);
		height: calc((100vw - 70px * 2) / 1);
	}

	.collection-wrapper {
		grid-column-gap: 70px;
		grid-row-gap: 60px;
		margin: 0 70px;
	}
}

@media only screen and (max-width: 450px) {
	.Collection .image {
		width: calc((100vw - 40px * 2) / 1);
		height: calc((100vw - 40px * 2) / 1);
	}

	.collection-wrapper {
		grid-column-gap: 40px;
		grid-row-gap: 40px;
		margin: 0 40px;
	}

	.Collection {
		margin-top: 70px;
	}

	/* .Collection .loading-wrapper {
		padding-bottom: 110px;
	} */
}