.top-banner {
	width: 100vw;
	height: 586px;
	background-color: white;
	margin-top: 100px;
	box-shadow: 0 40px 40px 0 rgba(0,0,0,0.04);
}

.top-banner p {
	background-color: rgba(0, 0, 0, 0);
	color: #292929;
}

.top-banner .wrapper {
	display: flex;
	flex-direction: row;
	background-color: white;
	justify-content: space-between;
	padding: 0 21vw;
}

.top-banner .infos {
	background-color: rgba(0, 0, 0, 0);
	/* min-width: max-content; */
	width: max-content;
}

.top-banner .header {
	margin-top: 110px;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 6.55px;
}

.top-banner .brand {
	margin-top: 100px;
	font-weight: 700;
	font-size: 24px;
	letter-spacing: 3px;
}


.top-banner .price-container- {
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-top: 20px;
}

.top-banner .salePrice- {
	color: #292929;
	font-weight: 600;
	font-size: 21px;
	letter-spacing: 1px;
}

.top-banner .originalPrice- {
	color: #292929;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 1px;
	margin-left: 20px;
	text-decoration: line-through;
}




.top-banner .image {
	height: 586px;
	object-fit: contain;
	background-color: white;
}








/*  */

.top-banner .content {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	text-decoration: none;
}

.new-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 69px;
	background-color: white;
}

.re {
	font-weight: 700;
	font-size: 24px;
	color: #000000;
	letter-spacing: 2px;
}

.en {
	font-weight: 600;
	font-size: 10px;
	color: #000000;
	letter-spacing: 4.3px;
	margin-left: 35px;
}







@media only screen and (max-width: 1755px) { 
	.top-banner .wrapper {
		padding: 0 17vw;
	}
}


@media only screen and (max-width: 1055px) { 
	.top-banner .wrapper {
		padding: 0 10vw;
	}
}

@media only screen and (max-width: 950px) {
	.top-banner {
		height: 291px;
		position: relative;
	}
	.top-banner {
		margin-top: 48px;
	}

	.top-banner .image {
		position: absolute;
		top: 0;
		right: 70px;
		height: 291px;
		z-index: 9;
	}

	.top-banner .infos {
		position: absolute;
		left: 70px;
		z-index: 10;
	}

	.top-banner .header {
		font-weight: 800;
		margin-top: 0;
		font-size: 12px;
		letter-spacing: 1px;
		margin-top: 40px;
	}
	
	.top-banner .brand {
		font-weight: 700;
		margin-top: 43px;
		font-size: 20px;
		letter-spacing: 1px;
	}

	.top-banner .price-container- {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 24px;
	}

	.top-banner .salePrice- {
		font-weight: 600;
		font-size: 16px;
		letter-spacing: 1px;
	}
	
	.top-banner .originalPrice- {
		font-weight: 500;
		font-size: 13px;
		margin-left: 0;
	}





	/* --------- */
	.new-wrapper {
		flex-direction: column;
		height: 50px;
	}

	.en {
		margin-left: 0;
	}
	
	.re {
		letter-spacing: 0.7px;
	}
}

@media only screen and (max-width: 540px) {
	.top-banner .image {
		right: 30px;
	}

	.top-banner .infos {
		left: 30px;
	}

	.top-banner .header {
		margin-top: 30px;
	}
}

@media only screen and (max-width: 450px) {
	.top-banner .image {
		right: 20px;
	}

	.top-banner .infos {
		left: 20px;
	}

	.top-banner .header {
		font-size: 10px;
		letter-spacing: 1px;
		margin-top: 20px;
	}
	
	.top-banner .brand {
		margin-top: 43px;
		font-size: 17px;
		letter-spacing: 1px;
	}

	.top-banner .price-container- {
		margin-top: 24px;
	}

	.top-banner .salePrice- {
		font-weight: 600;
		font-size: 14px;
		letter-spacing: 1px;
	}
	
	.top-banner .originalPrice- {
		font-weight: 500;
		font-size: 12px;
	}
}