.Footer {
	position: absolute;
	width: 100vw;	
	display: flex;
	justify-content: center;
	margin-bottom: 37px;
	bottom: 0;
	z-index: 10;
	padding: 0 152px;
}

.Footer .footer-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.Footer .links {
	width: 381px;
	display: flex;
	justify-content: space-between;
	word-wrap: break-word;
	align-items: center;
}

.Footer .label {
	font-weight: 500;
	font-size: 12px;
	color: #929292;
	letter-spacing: 1px;
	text-decoration: none;
}

.Footer .appLink {
	height: max-content;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.Footer .app-icon {
	width: 46px;
	height: 46px;
}

.appLink .text {
	font-weight: 700;
	font-size: 11px;
	color: #272727;
	letter-spacing: 1px;
	text-align: center;
}



@media only screen and (min-width: 2138px) {
	.Footer { padding: 0 calc((100vw - 510px * 3) / 4) }
}


/* TALET */
@media only screen and (max-width: 1050px) {
	.Footer { padding: 0 100px; }
}

@media only screen and (max-width: 800px) {
	.Footer { padding: 0 75px; }
}



@media only screen and (max-width: 1050px) {
	.Footer { padding: 0; }
	.Footer .footer-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.footer-container .appLink {
		flex-direction: column;
	}

	.appLink .text {
		margin-top: 6px;
	}

	.links {
		margin-top: 49px;

	}
}




/* Small */
@media only screen and (max-width: 500px) {
	.Footer .footer-container {
		padding: 0 30px;
	}

	.Footer .label {
		font-size: 11px;
		letter-spacing: 0px;
	}

	.links {
		max-width: 100%;
	}
}

@media only screen and (max-width: 400px) {
	.Footer .footer-container {
		padding: 0 30px;
	}

	.Footer .label {
		font-weight: 500;
		font-size: 10px;
		color: #929292;
		letter-spacing: 0px;
		text-decoration: none;
	}
}

@media only screen and (max-width: 320px) {
	.Footer .footer-container {
		padding: 0 25px;
	}

	.Footer .label {
		font-size: 9px;
	}
}

@media only screen and (max-width: 260px) {
	.Footer .footer-container {
		padding: 0 20px;
	}

	.Footer .label {
		font-size: 8px;
	}
}