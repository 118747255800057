.Page404 {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Page404 .wrapper {
	display: inline;
}

.Page404 h1 {
	font-size: 28px;
	letter-spacing: 5px;
	font-weight: 700;
}

.Page404 h2 {
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 2px;
	margin: 8px 0 0 0;
}

.Page404 .link p {
	padding: 10px 0 0 29px;
	background-color: #303030;
	color: white;
	width: 164px;
	height: 36px;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 6px;
	text-align: left;
	margin-top: 54px;
}


@media only screen and (max-width: 1100px) {
	.Page404 .wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.Page404 .link p {
		padding-left: 0;
		text-align: center;
		margin-top: 0;
	}

	.Page404 .link {
		margin-top: 54px;
	}

	.Page404 h2 {
		font-size: 10px;
		font-weight: 600;
		letter-spacing: 1.5px;
		text-align: center;
		margin: 8px 15px 0 15px;
	}

	.WholePage404 {
		position: relative;
		padding-bottom: 170px;
	}
}
